import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/navbar.js';
import Home from './pages/home/home.js';
import About from './pages/about/about.js';
import Skills from './pages/skills/skills.js';
import Portfolio from './pages/portfolio/portfolio.js'
import Contact from './pages/contact/contact.js';
import Footer from './components/footer/footer.js';


const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/skills' element={<Skills />} />
        <Route exact path='/portfolio' element={<Portfolio />} />
        <Route exact path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App;
