import React, { useState } from 'react';
import './portfolio.css';
import project1 from '../../assets/cinemamov1.png';
import project2 from '../../assets/authentication.png';
import project3 from '../../assets/chatbot.png';
import project4 from '../../assets/foodRecipe.png';
import carouselArrow from '../../assets/arrow.svg';
import PortfolioImg from '../../assets/portfolio.png';

const Portfolio = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const projects = [
    {
      img: project1,
      title: 'Movie App',
      description: 'This movie app project demonstrates proficiency in building a movie application using MERN. The frontend is built with React, Redux Toolkit, and Tailwind CSS, featuring responsive design, API integration, state management, and modern UI/UX principles.',
      technologies: 'React, Tailwindcss, TMDB API',
      demo: 'https://cinemamov.netlify.app/',
      repo: 'https://github.com/MarGit19/movies.git'
    },
    {
      img: project2,
      title: 'Authentication App',
      description: 'This project demonstrates the implementation of an authentication system using the MERN stack. The frontend is built with React, leveraging React Router for client-side routing. The backend is built with Node.js and Express and uses MongoDB as the database. JSON Web Token (JWT) is used for authentication.',
      technologies: 'MongoDB, Express, React Vite, Node.js, Firebase',
      demo: 'https://auth-app-pnaf.onrender.com/',
      repo: 'https://github.com/MarGit19/authentication-app'
    },
    {
      img: project3,
      title: 'Chatbot App',
      description: 'This project is an interactive and user-friendly JS chatbot application designed to provide instant responses to user queries by leveraging the power of the ChatGPT API. This project aims to create a seamless and engaging user experience for anyone needing quick and reliable information or assistance.',
      technologies: 'HTML, CSS, JS, OpenAI API',
      demo: 'https://chattabox.netlify.app/',
      repo: 'https://github.com/MarGit19/chatbot-app'
    },
    {
      img: project4,
      title: 'Food Recipe App',
      description: 'The Food Recipe CRUD App is a straightforward and robust application designed to help users manage their food recipes with ease. Users can effortlessly create, read, update, and delete recipes through an intuitive interface, making it an ideal tool for organizing and maintaining personal recipe collections.',
      technologies: 'HTML, CSS, JS',
      demo: 'https://recipe-tracker-app-rrv4.onrender.com/',
      repo: 'https://github.com/MarGit19/recipe-app'
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % projects.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + projects.length) % projects.length);
  };

  return (
    <div className="portfolio-container">
      <div className="portfolio-header-container">
        <h2>Portfolio</h2>
        <img src={PortfolioImg} alt='portfolio-img' />
      </div>
      <div className="portfolio-image-carousel-container">
        <div className="portfolio-carousel-container" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {projects.map((project, index) => (
            <div className="portfolio-carousel-slide" key={index}>
              <div className="portfolio-image-container">
                <img src={project.img} alt={`${project.title}-img`} />
              </div>
              <h3><b>{project.title}</b></h3>
              <p><b>Description: </b>{project.description}</p>
              <p><b>Technologies: </b>{project.technologies}</p>
              <a href={project.demo} target="_blank" rel="noopener noreferrer"><button className="project-button">Demo</button></a>
              <a href={project.repo} target="_blank" rel="noopener noreferrer"><button className="project-button">Repo</button></a>
            </div>
          ))}
        </div>
        <button className="portfolio-carousel-button left" onClick={prevSlide}>
          <img src={carouselArrow} alt="carousel-arrow-left" />
        </button>
        <button className="portfolio-carousel-button right" onClick={nextSlide}>
          <img src={carouselArrow} alt="carousel-arrow-right" />
        </button>
      </div>
    </div>
  );
};

export default Portfolio;
