import React from 'react';
import { useNavigate } from 'react-router-dom';
import './about.css';
import AboutMe from '../../assets/about.png';

const About = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate('/skills');
  };

  return (
    <div>
      <div className='about-container'>
        <div className='about-demo'>
          <img src={AboutMe} alt='about-img' />
        </div>
        <div className='about-intro'>
          <h2>About Me</h2>
          <p>
            I'm Martin Uche, a former healthcare professional that transitioned into Software Engineer. I hold a Bachelor of Science degree in Chemistry from California State University, Bakersfield, and a Master's degree in Public Administration (Healthcare Management) from California State University, East Bay, Hayward. However, my current passion lies in software development!
          </p>
          <p>
            I have a relentless curiosity about how things work, and I channel that curiosity into creating and enhancing applications that I find meaningful. Whether it's exploring new technologies or refining existing ones, I thrive on the creative process of software engineering.
          </p>
          <p>
            In my leisure time, I find joy in hiking and immersing myself in the world of soccer, particularly as a fervent supporter of Manchester United Football Club. Music, especially Afrobeats, holds a special place in my heart, providing inspiration and relaxation.
          </p>
          <p>
            Join me as I navigate the journey of a software engineer, blending my technical skills with a passion for innovation and continuous learning.
          </p>
          <button className='learn-more-button' onClick={handleLearnMoreClick}>Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default About;
