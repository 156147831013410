import React from 'react';
import './footer.css';
import linkedin from '../../assets/linkedin-logo.svg';
import instagram from '../../assets/instagram-logo.svg';
import github from '../../assets/github-logo.svg';

const Footer = () => {
  return (
    <footer className="footer">
            <div className="footer-content">
                <div className="footer-left">
                    <p>&copy; 2024 Martin Uche. All rights reserved.</p>
                </div>
                <div className="footer-right">
                    <a href="https://www.linkedin.com/in/martinuche19" target="_blank" className="social-icon" rel="noreferrer">
                        <img src={linkedin} alt="LinkedIn" />
                    </a>
                    <a href="https://www.instagram.com/martigram19" target="_blank" className="social-icon" rel="noreferrer">
                        <img src={instagram} alt="Instagram" />
                    </a>
                    <a href="https://www.github.com/MarGit19" target="_blank" className="social-icon" rel="noreferrer">
                        <img src={github} alt="GitHub" />
                    </a>
                </div>
            </div>
        </footer>
  );
}

export default Footer;
