import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './contact.css';
import ContactImage from '../../assets/contact.png';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [message, setMessage] = useState(null); 

  const serviceID = 'service_wusfxn8';
  const templateID = 'template_3vxqa5n';
  const userID = 'gICDGpFLYN45sUFi3';
  const messageDisplayDuration = 10000;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    emailjs.send(
      serviceID,
      templateID,
      formData,
      userID
    ).then((response) => {
      console.log('Email sent successfully:', response.status, response.text);
      setMessage('Email sent successfully!');
      
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      
      setTimeout(() => {
        setMessage(null);
      }, messageDisplayDuration);
    }).catch((err) => {
      console.error('Failed to send email:', err);
      setMessage('Failed to send email. Please try again later.');

      setTimeout(() => {
        setMessage(null);
      }, messageDisplayDuration);
    });
  };

  return (
    <div className="contact-container">
      <div className="contact-img">
        <img src={ContactImage} alt="contact-pic" />
      </div>
      <div className="contact-form-container">
        <div className="contact-form-intro">
          <h2>Contact</h2>
          <p>If you have any questions or concerns, please don't hesitate to reach out!</p>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <textarea
            id="message"
            name="message"
            rows="5"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button className="contact-send-button" type="submit">Send</button>
          {message && <p className={`form-message ${message.includes('successfully') ? 'success' : 'error'}`}>{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default Contact;
