import React from 'react';
import { useNavigate } from 'react-router-dom';
import './skills.css';
import skillsImage from '../../assets/skills.png';
import resume from '../../assets/resume.pdf';

const Skills = () => {
  const navigate = useNavigate();

  const handleContactMeClick = () => {
    navigate('/contact');
  };

  return (
    <div className="skills-container">
      <div className="skills-demo">
        <h2>Skills</h2>
        <img src={skillsImage} alt="skills-img" />
        <a href={resume} download className="download-resume-button">Download Resume</a>
        <button className="skills-button" onClick={handleContactMeClick}>Contact Me</button>
      </div>
      <div className="skills-intro">
        <h3>Languages</h3>
        <ul>
          <li>JavaScript</li>
          <li>Python</li>
        </ul>
        <hr className='skills-intro-hr' />
        <h3>Frameworks</h3>
        <ul>
          <li>React</li>
          <li>React Native</li>
          <li>Angular</li>
        </ul>
        <hr className='skills-intro-hr' />
        <h3>Frontend</h3>
        <ul>
          <li>HTML5</li>
          <li>CSS</li>
          <li>Bootstrap</li>
          <li>Tailwind CSS</li>
          <li>Material UI</li>
        </ul>
        <hr className='skills-intro-hr' />
        <h3>Backend</h3>
        <ul>
          <li>Node.js</li>
          <li>Express.js</li>
        </ul>
        <hr className='skills-intro-hr' />
        <h3>Databases</h3>
        <ul>
          <li>MongoDB</li>
          <li>GraphQL</li>
          <li>MySQL</li>
        </ul>
        <hr className='skills-intro-hr' />
        <h3>Tools and Platforms</h3>
        <ul>
          <li>Git</li>
          <li>Firebase</li>
        </ul>
        <hr className='skills-intro-hr' />
        <h3>Additional Technicalities</h3>
        <ul>
          <li>Algorithms</li>
          <li>Data Structures</li>
          <li>Project Management</li>
        </ul>
      </div>
    </div>
  );
};

export default Skills;
