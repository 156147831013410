import React, { useState, useEffect, useMemo } from 'react';
import './home.css'; // Make sure this path is correct
import Hero from '../../assets/home.png';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  // Memoize the titles array to optimize performance
  const titles = useMemo(() => [
    "Software Engineer",
    "Project Manager",
    "Tech Enthusiast",
    "Agile Champion",
    "Music Lover",
    "Soccer Fervent"
  ], []);

  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTitleIndex(currentIndex => (currentIndex + 1) % titles.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [titles]);

  return (
    <div className='home-container'>
      <div className='home-intro'>
        <h1>I'm Martin</h1>
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.9 }}
        >
          {titles[currentTitleIndex]}
        </motion.h2>
        <p>I am a full-stack developer located in Dallas, Texas, with a keen interest in leveraging cutting-edge technologies to enhance my applications. My expertise lies in the MERN stack, where I thrive on creating robust and dynamic web solutions.</p>
        <button className='home-button' onClick={() => navigate('/portfolio')}>My Portfolio</button>
        <button className='home-button' onClick={() => navigate('/contact')}>Contact Me</button>
      </div>
      <div className='home-demo'>
        <img src={Hero} alt='home-img' />
      </div>
    </div>
  );
};

export default Home;
